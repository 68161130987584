<template>
<div style="max-width: 800px">

  <div class="card-box">
    <h4>Top Audio Fingerprints</h4>
    <p>View the top audio fingerprints on our radar</p>
    <CButton @click="loadPath('calls-radar/audio-fingerprints')" color="info" class="card-box-button">
      Top Audio Fingerprints
    </CButton>
  </div>

</div>
</template>

<script>
export default {
  name: 'Calls-Radar',
  methods: {
    //--------------------------------------------------------------------------
    loadPath(path) {
      this.$router.push({
        path: path
      });
    },
  }
}
</script>
